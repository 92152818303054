export default defineNuxtRouteMiddleware((_to, _from) => {
  const authStore = useAuthStore()
  const layoutStore = useLayoutStore()

  layoutStore.getPlatform()

  if (!authStore.token && layoutStore.platform === 'web') {
    return navigateTo('/')
  }
})
